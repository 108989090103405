import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { UserSubscriptionChangeOptionStore } from '@seedlang/stores';
import { absoluteCenter, fixedCenter, flexCenterColumn, smallText } from '@seedlang/style_mixins';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Alert from 'components/alert';
import PaymentButton from 'components/button/payment_button';
import PaymentOption from 'components/checkout/payment_option';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Spinner from 'components/spinner';
import WhitelabelLogo from "components/whitelabel_logo";
import { noop } from 'lodash';
import { autorun, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import CheckoutRegistrationStep from './checkout_registration_step';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  ${smallText()}
  margin-bottom: ${props => props.isMobile ? '0' : '20px'};
  ${fixedCenter()}
  background: ${props => props.background};
  z-index: ${Theme.z.foreground};
`;

const Title = styled.div`
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin: ${props => props.margin};
  word-break: break-word;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  ${absoluteCenter()}
  background: ${props => props.background};
  border-radius: ${(props) => props.isMobile ? 0 : '20px'};
  max-width: ${(props) => props.isMobile ? '100%' : '500px'};
  max-height: ${props => props.maxHeight};
  height: ${(props) => props.isMobile ? '100%' : 'auto'};
  margin: ${(props) => props.isMobile ? '0' : '60px auto'};
  width: 100%;
  z-index: 4;
  padding: 20px;
  overflow: auto;
`;

const InnerContent = styled.div`
${flexCenterColumn()}
  height: 100%;
  width: 100%;
  background: #FFF;
  border-radius: 10px;
`;

const PaymentWrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  padding: 0 20px;
  background: #FFF;
  border-radius: 5px;
`;

const PaymentOptions = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const Info = styled.div`
  font-size: 14px;
  opacity: 0.8;
  margin: 10px 0;
  text-align: center;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
  .img {
    width: 190px;
  }
`;

const RedirectMessage = styled.div`
  background: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  opacity: 0.8;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
`;


const ErrorMessageWrapper = styled.div`
  margin: 0 0px 10px 0px;
`

const FailureMessage = styled.div`
  margin: 20px 0;
`;



@observer
class CheckoutSeedlang extends React.Component {
  @observable selectedCreditCardOption = null;
  @observable failedPayment = false;
  @observable failureMessage;
  @observable paymentType = "stripe";
  @observable startedCheckoutAsUnregistered = false;
  @observable show = 'checkout';
  @observable registrationComplete = false;
  @observable setViewedCheckoutCount = false;
  @observable authType = null;
  @observable returnedFromStripe = false;
  @observable showRedirectError = false;
  @observable isDataLoaded = false;

  constructor(props) {
    super(props);
    if (document.referrer.match('checkout.stripe.com') && AppUI.getCookieValue('redirected-to-stripe')) {
      // checkout.stripe.com stays as the document.referrer even when it's not the last click
      // need to protect against having a loop between memberships.index and this page
      // which would make it impossible to get to the checkout page
      AppUI.setCookieValue('redirected-to-stripe', false)
      AppUI.routeStore.routeToNamed('memberships.index');
    } else {
      if (!AppUI.currentlyViewedMembership || AppUI.currentlyViewedMembership.id !== this.props.params.membershipId) {
        AppUI.membershipStore.getShow({ids: {membershipId: this.props.params.membershipId}});
      }
      AppUI.createEvent("checkout - payment form", {membership_id: this.props.params.membershipId});

      this.disposer = autorun(() => {
        if (AppUI.user && (AppUI.user.guest || AppUI.user.signedOut)) {
          this.startedCheckoutAsUnregistered = true;
          this.show = 'registration';
        } else if (AppUI.userIsLoaded && !AppUI.user.guest && !this.setViewedCheckoutCount) {
          this.setViewedCheckoutCount = true;
          AppUI.user.set('viewedCheckoutCount', AppUI.user.viewedCheckoutCount + 1);
          AppUI.authUserStore.update({data: {viewedCheckoutCount: AppUI.user.viewedCheckoutCount}}, noop);
        }
      })
    }
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
    AppUI.set('currentlyViewedMembership', null);
  }

  @computed get subscriptionChangeOption() {
    if (!UserSubscriptionChangeOptionStore.hasIndexData) return null;
    return UserSubscriptionChangeOptionStore.indexData.find(item => item.membershipId === this.props.params.membershipId)
  }

  @computed get contentMaxHeight() {
    if (AppUI.layout.isMobile) {
      return '100%';
    }
    if (AppUI.siteIsDefault) {
      return '500px;'
    }
    return AppUI.showPatreonButton ? '640px' : '600px';
  }

  @computed get buttonText() {
    let str = `Pay ${this.membership.costFormatted && this.membership.costFormatted(AppUI.user.currency, this.quantity, this.recurringPayments)}`;
    str = `${str} per ${this.membership.numberMonths > 1 ? this.membership.numberMonths : 1} Month${this.membership.numberMonths > 1 ? 's' : ''}`;
    return str;
  }

  @computed get backgroundColor() {
    if (AppUI.siteIsDefault) {
      return 'transparent';
    } else {
      return isPresent(AppUI.site?.footerBackgroundColor) ? AppUI.site?.footerBackgroundColor : "#044067";
    }
  }

  @computed get showSpinner() {
    return AppUI.submittingPayment;
  }

  @computed get disablePaymentButton() {
    return (this.startedCheckoutAsUnregistered && !this.registrationComplete)
  }

  @computed get membership() {
    return AppUI.currentlyViewedMembership || AppUI.membershipStore.showData;
  }

  @computed get hasMembership() {
    return AppUI.membershipStore.hasShowData;
  }

  @computed get showNewCreditCardForm() {
    return this.stripeOption === 'new' || isBlank(this.stripeOption);
  }

  get isStripeEnabled() {
    return true;
  }

  get isPaypalEnabled() {
    return AppUI.siteIsDefault;
  }

  get isPaypalViaStripeEnabled() {
    return !AppUI.siteIsDefault;
  }

  @autobind createPayment() {
    this.reset();
    this.setSubmittingPayment();
    if (this.paymentType === 'stripe') {
      AppUI.createEvent('checkout - click stripe button');
      this.forwardToStripe();
    } else if (this.paymentType === 'paypal') {
      AppUI.createEvent('checkout - click paypal button');
      AppUI.paypalStore.create({data: {
        membership_id: this.props.params.membershipId
      }}, this.afterCreatePaypalLink)
    }
  }

  @autobind afterCreateStripeLink(resp) {
    window.location = resp.url;
  }

  @autobind setSubmittingPayment() {
    AppUI.set('submittingPayment', true);
  }

  @autobind afterCreatePayment(resp) {
    AppUI.set('submittingPayment', false);
    if (resp["success"]) {
      AppUI.routeStore.routeToNamed('memberships.thank_you');
      AppUI.loadUser();
    } else {
      this.failedPayment = true;
      AppUI.createEvent('checkout - failed payment');
    }
    if (this.failedPayment) {
      this.failureMessage = resp["message"];
    }
  }

  @autobind afterCreatePaypalLink(resp) {
    if (resp.success) {
      if (resp.url) {
        window.location = resp.url;
      }
      else {
        console.warn('No url returned from the API while creating a PayPal link.')
        this.afterCreatePaymentFailure(resp);
      }
    }
    else {
      this.afterCreatePaymentFailure(resp);
    }
  }

  @autobind afterCreatePaymentFailure(resp) {
    AppUI.set('submittingPayment', false);
    this.failureMessage = resp["message"];
    this.failedPayment = true;
  }

  @autobind reset() {
    this.failedPayment = false;
  }

  @autobind setSelectedCreditCardOption(val) {
    this.selectedCreditCardOption = val;
  }

  @autobind onSelectPaymentType(paymentType) {
    this.paymentType = paymentType;
  }

  @autobind setShow(show) {
    this.show = show;
  }

  @autobind afterRegistration() {
    AppUI.loadUser();
    this.show = 'checkout';
    this.registrationComplete = true;
  }

  @autobind forwardToStripe() {
    AppUI.setCookieValue('redirected-to-stripe', true)
    AppUI.stripeStore.createSession({data: {
      quantity: 1,
      membership_id: this.props.params.membershipId
    }}, this.afterCreateStripeLink)
  }

  render() {
    return (
      <Wrapper
        className="Checkout__Wrapper"
        background={this.backgroundColor}
        isMobile={AppUI.layout.isMobile}
      >
        {
          this.membership &&
            <Content
              className="Checkout__Content"
              background={AppUI.siteIsDefault ? "linear-gradient(-134deg, rgb(31, 204, 100) 0%, rgb(69, 119, 94) 100%)" : "#FFF"}
              maxHeight={this.contentMaxHeight}
              isMobile={AppUI.layout.isMobile}
            >
              <InnerContent className="Checkount__InnerContent">
                <Title
                  margin={AppUI.siteIsDefault ? "0 0 20px 0" : "0 0 10px 0"}
                >
                  {AppUI.siteIsDefault ? 'Complete Your Purchase' : this.subscriptionChangeOption ? 'Changing your Subscription' : 'Become a Member'}
                </Title>
                {
                  this.show === 'loading' &&
                    <Spinner background={AppUI.site.accentColor || Theme.blue} />
                }

                {
                  this.show === 'registration' &&
                    <CheckoutRegistrationStep afterRegistration={this.afterRegistration} />
                }
                {
                  this.show === 'checkout' &&
                    <PaymentWrapper>
                      {this.renderErrorMessages()}

                      {
                        this.startedCheckoutAsUnregistered && !this.registrationComplete &&
                          <Alert
                            width="370px"
                            borderRadius="5px"
                          >
                            Registration must be completed before you can checkout.
                          </Alert>
                      }
                      <PaymentOptions>
                        {
                          this.isStripeEnabled &&
                            <PaymentOption
                              text={this.isPaypalViaStripeEnabled ? "Credit Card or PayPal" : "Credit Card"}
                              selected={this.paymentType === 'stripe'}
                              onClick={() => this.onSelectPaymentType('stripe')}
                            />
                        }
                        {
                          this.isPaypalEnabled &&
                            <PaymentOption
                              text="Paypal"
                              image="/images/paypal.png"
                              selected={this.paymentType === 'paypal'}
                              onClick={() => this.onSelectPaymentType('paypal')}
                            />
                        }
                      </PaymentOptions>
                      {
                        this.showSpinner &&
                          <Spinner className='blue' />
                      }
                      {
                        this.paymentType === 'paypal' &&
                          <RedirectMessage>
                            You will be redirected to Paypal to complete your payment.
                          </RedirectMessage>
                      }
                      {
                        this.paymentType === 'stripe' &&
                          <RedirectMessage>
                            You will be redirected to Stripe to complete your payment.
                          </RedirectMessage>
                      }
                      {
                        this.membership &&
                          <PaymentButton
                            onClick={this.createPayment}
                            disabled={this.disablePaymentButton}
                          >
                            {
                              this.paymentType === 'paypal' &&
                                <img
                                  src="/images/paypal.png"
                                  alt="Paypal logo"
                                  style={{
                                    width: 20,
                                    margin: "10px 5px 0 0",
                                  }}
                                />
                            }
                            <span>
                              {this.buttonText}
                            </span>
                          </PaymentButton>
                      }
                      <Info>
                        15 day money-back guarantee.  Cancel anytime.
                      </Info>
                    </PaymentWrapper>
                }
              </InnerContent>
            </Content>
          }

        </Wrapper>
    );
  }

  renderErrorMessages() {
    return (
      <ErrorMessageWrapper className="Checkout__ErrorMessageWrapper">
        {
          AppUI.user.isMember &&
            <Alert
              width="100%"
              borderRadius="5px"
            >
              <b>You already have an active membership.</b> If you would like to change your subscription, you can continue and we will cancel your previous subscription for you if it was previously made on the website. <a href="/settings/billing" target="_blank" style={{ textDecoration: "underline", cursor: "pointer" }}>View your billing page</a>.
            </Alert>
        }
        {
          this.failedPayment &&
            <Alert
              width="100%"
              borderRadius="5px"
              failure
            >
              <div>
                <div>We are sorry, there was a problem processing your payment. Our payment provider returned this message:</div>
                <FailureMessage>
                  {this.failureMessage}
                </FailureMessage>
                <div>
                  <span> You can contact us on our </span>
                  <Link
                    to={{ name: 'contact' }}
                  >
                    Contact page
                  </Link>
                  <span> for assistance.</span>
                </div>
              </div>
            </Alert>
        }
      </ErrorMessageWrapper>
    )
  }
}

export default ErrorBoundary(CheckoutSeedlang);
