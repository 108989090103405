import React from 'react';
import { observer } from 'mobx-react';
import { WorksheetStore, ForumThreadStore, GridItemWorksheetStore } from '@seedlang/stores';
import WorksheetCreate from 'pages/creator/worksheets/worksheet_create';
import CheckIfTrue from 'components/check_if_true';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import InPlaceText from 'components/form/in_place_text';
import Paginator from 'components/paginator';
import styled from "@emotion/styled";
import ReactTooltip from 'react-tooltip';
import { PinIcon } from "@seedlang/icons";
import Filter from "components/backend_filter/filter";
import SortableColumns from "components/hoc/sortable_columns";
import cx from "classnames";
import InfoTooltip from "components/info_tooltip";
import Checkbox from "components/checkbox";
import { computed, observable } from "mobx";
import { every, flatten, noop, remove, uniq, uniqBy } from "lodash";
import Button from 'components/button/button';
import { AppUI } from "@seedlang/state";
import { Theme } from "@seedlang/constants/src";
import { isBlank } from '@seedlang/utils';
import OutlineButton from 'components/button/outline_button';
import Text from 'components/text';
import Modal from "components/modal";
import TagEdit from "pages/creator/tags/tag_edit";
import Alert from "components/alert";
import GridItemSearch from "components/grid_item_search";


const InvalidIconWrapper = styled.div`
  text-transform: none;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .pin-icon {
    height: 14px;
    width: 14px;
  }
  svg {
    width: 14px;
    fill: #333;
  }
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  .fa {
    margin-right: 5px;
  }
`;

const SelectedPostsWrapper = styled.div`
  padding: 10px;
  margin: 0 0 10px 0;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

const GridSectionIndex = styled.div`
  margin-bottom: 20px;
  font-size: 80%;
  .underline {
    font-size: 90%;
    color: #06069c;
  }
  .underline:hover {
    text-decoration: none;
  }
  ul li {
    list-style-type: disc;
    margin-left: 20px !important;
  }
  ul ul {
    margin: 0 0 0.2rem 0.5rem;
    font-size: 90%;
  }
`;

@observer
class WorksheetIndex extends React.Component {
  @observable selectedWorksheetIds = [];
  @observable allWorksheetsSelected = false;
  @observable editTags = false;
  @observable editGridSection = false;

  componentDidMount () {
    this.reloadWorksheets();
  }

  @autobind reloadWorksheets() {
    WorksheetStore.reloadIndexFiltered({queryStrings: {expanded: true, show_all: true, include_count: true}, ids: {groupId: this.props.params.groupId}});
  }

  @autobind onToggleArchive(id) {
    const worksheet = WorksheetStore.indexData.find(item => item.id === id);
    if (worksheet.archived) {
      WorksheetStore.update({data: {archived: false}, ids: {worksheetId: id}}, this.reloadWorksheets);
    } else {
      WorksheetStore.destroy({ids: {worksheetId: id}}, this.afterUpdate);
    }
  }

  @autobind afterUpdate() {
    WorksheetStore.reloadIndexFiltered({queryStrings: {expanded: true, show_all: true}, ids: {groupId: this.props.params.groupId}});
  }

  @autobind onSelectWorksheet(episode) {
    if (this.selectedWorksheetIds.indexOf(episode.id) !== -1) {
      remove(this.selectedWorksheetIds, (item) => item === episode.id)
    } else {
      this.selectedWorksheetIds.push(episode.id)
    }
  }

  @autobind selectAllWorksheets() {
    if (this.allWorksheetsSelected) {
      this.allWorksheetsSelected = false;
      this.selectedWorksheetIds = [];
    } else {
      this.allWorksheetsSelected = true;
      WorksheetStore.indexData.forEach(item => {
        if (this.selectedWorksheetIds.indexOf(item.id) === -1) {
          this.selectedWorksheetIds.push(item.id);
        }
      });
    }
  }

  @autobind destroySelectedWorksheets() {
    if (this.archivedFilter) {
      WorksheetStore.batchUpdate({data: {ids: this.selectedWorksheetIds, archived: false}}, this.afterDestroySelectedWorksheets);
    }
    WorksheetStore.batchDelete({data: {ids: this.selectedWorksheetIds}}, this.afterDestroySelectedWorksheets);
  }

  @autobind afterDestroySelectedWorksheets() {
    this.selectedWorksheetIds = [];
    this.reloadWorksheets();
  }

  @computed get selectedWorksheets() {
    return WorksheetStore.indexData.filter(item => this.selectedWorksheetIds.indexOf(item.id) !== -1);
  }

  @computed get allSelectedWorksheetsHaveHiddenComments() {
    return every(this.selectedWorksheets.map(item => item.forumThread?.hidden));
  }

  @autobind toggleShowComments() {
    const hiddenValue = !this.allSelectedWorksheetsHaveHiddenComments;
    ForumThreadStore.batchUpdate({data: {forumThreadIds: this.selectedWorksheets?.map(item => item.forumThread?.id).filter(item => item), hidden: hiddenValue}}, noop);
    this.selectedWorksheets.forEach(item => item.forumThread.set('hidden', hiddenValue));
  }

  @computed get selectedWorksheetsGridSections() {
    return uniqBy(flatten(this.selectedWorksheets.map(item => item.gridItems)), item => item.id);
  }

  @autobind onAddGridItem(gridItem) {
    GridItemWorksheetStore.batchCreate({data: {gridItemId: gridItem.id, worksheetIds: this.selectedWorksheetIds}}, this.reloadWorksheets);
  }

  @autobind onRemoveFromGridItem(gridItem) {
    GridItemWorksheetStore.batchDelete({data: {gridItemId: gridItem.id, worksheetIds: this.selectedWorksheetsPerGridItem(gridItem).map(item => item.id)}}, this.reloadWorksheets);
  }

  @autobind selectedWorksheetsPerGridItem(gridItem) {
    return this.selectedWorksheets.filter(item => item.gridItems.map(i => i.id).indexOf(gridItem.id) !== -1);
  }

  @computed get archivedFilter() {
    return WorksheetStore.filters['archived'] === true || WorksheetStore.filters['archived'] === 'true';
  }

  render() {
    return (
      <div className='worksheet-index'>
        {
          this.editTags &&
            <Modal
              onCloseModal={() => this.editTags = false}
              height="550px"
              title="Edit Tags"
              background={AppUI.site.accentColor || Theme.gray}
              contentShouldScroll
            >
              <TagEdit
                groupId={this.props.params.groupId}
                worksheets={this.selectedWorksheets}
                onChange={this.reloadWorksheets}
              />
            </Modal>
        }
        {
          this.editGridSection &&
            <Modal
              onCloseModal={() => this.editGridSection = false}
              height="550px"
              title="Edit Grid Sections"
              background={AppUI.site.accentColor || Theme.gray}
              contentShouldScroll
            >
              <GridSectionIndex>
                <Text margin='5px 0' bold fontSize='16px'>Selected Posts' Grid Sections</Text>
                <ul>
                  {
                    this.selectedWorksheets.filter(item => item.gridItems.length === 0).length > 0 &&
                    <>
                      <li><b>Posts with no grid sections</b></li>
                      <ul>
                        {
                          this.selectedWorksheets.filter(item => item.gridItems.length === 0).map(worksheet => {
                            return (
                              <li key={`grid-section-index-${worksheet.id}`}>{worksheet.name}</li>
                            )
                          })
                        }
                      </ul>
                    </>
                  }
                  {
                    this.selectedWorksheetsGridSections.map(gridItem =>
                      (
                        <>
                          <li key={gridItem.id}><b>{gridItem.name}</b> [<span className='underline' onClick={() => this.onRemoveFromGridItem(gridItem)}>{`Remove ${this.selectedWorksheetsPerGridItem(gridItem).length} Posts`}</span>]</li>
                          <ul>
                            {
                              this.selectedWorksheets.map(worksheet => {
                                  if (worksheet.gridItems.map(i => i.id).indexOf(gridItem.id) !== -1) {
                                    return (
                                      <li key={`${worksheet.id}-${gridItem.id}`}>{worksheet.name}</li>
                                    )
                                  }
                                }
                              )
                            }
                          </ul>
                        </>
                      )
                    )
                  }
                </ul>
              </GridSectionIndex>
              <Text small>{`Search for a Grid Section below to add the ${this.selectedWorksheets.length} posts to it.`}</Text>
              <GridItemSearch
                onSubmit={this.onAddGridItem}
              />
            </Modal>
        }
        <div className='breadcrumbs'>
          <div>
            Posts
          </div>
        </div>
        <WorksheetCreate
          groupId={this.props.params.groupId}
        />
        <Filter
          store={WorksheetStore}
          queryStrings={{include_count: true, show_all: true, expanded: true}}
          filters={
            [
              {
                type: 'text',
                name: 'name',
                label: 'Name',
                placeholder: 'Enter Name',
                default: ''
              },
              {
                type: 'boolean',
                label: 'is Published',
                name: 'published',
                default: ''
              },
              {
                type: 'boolean',
                label: 'Archived',
                name: 'archived',
                default: false,
                withoutAny: true,
                afterChange: () => this.selectedWorksheetIds = [],
              },
              {
                type: 'boolean',
                label: 'Open to All',
                name: 'access_without_membership',
                default: ''
              },
              {
                type: 'boolean',
                label: 'has Youtube video',
                name: 'youtube_url',
                default: ''
              },
              {
                type: 'boolean',
                name: 'podcast_episode_id',
                label: 'has Podcast Episode',
                default: ''
              },
              {
                type: 'boolean',
                name: 'publish_words',
                label: 'Has Published Vocab',
                default: ''
              },
              {
                type: 'boolean',
                name: 'exercise_entries_count',
                label: 'Has Exercises',
                default: ''
              },
              {
                type: 'boolean',
                name: 'description',
                label: 'Has Description',
                default: ''
              },
              {
                type: 'number',
                name: 'grid_items_count',
                label: '# Grid Section(s)',
              },
              {
                type: 'date',
                name: 'published_at',
                label: 'Published at',
                default: ''
              },
            ]
          }
        />
        {
          this.selectedWorksheetIds.length > 0 &&
          <SelectedPostsWrapper>
            <Text small bold center>{`${this.selectedWorksheetIds.length} posts selected`}</Text>
            <ActionButtonsWrapper>
              <OutlineButton
                fontSize='14px'
                onClick={() => this.editTags = true}
              >
                <i className='fa fa-tag' /> Edit tags
              </OutlineButton>
              {
                AppUI.site.useGridLayout &&
                  <OutlineButton
                    fontSize='14px'
                    onClick={() => this.editGridSection = true}
                  >
                    Edit Grid Sections
                  </OutlineButton>
              }
              {
                AppUI.site.showWorksheetComments &&
                  <OutlineButton
                    fontSize='14px'
                    onClick={this.toggleShowComments}
                  >
                    {this.allSelectedWorksheetsHaveHiddenComments ? <i className='fa fa-eye' /> : <i className='fa fa-eye-slash' />}
                    {this.allSelectedWorksheetsHaveHiddenComments ? 'Show Comments' : 'Hide Comments'}
                  </OutlineButton>
              }
              <DeleteButton
                confirmationMessageFloatsRight
                left='200px'
                right='auto'
                message={`${this.selectedWorksheetIds.length} worksheets will no longer be visible for users`}
                disabled={isBlank(this.selectedWorksheetIds)}
                onConfirm={this.destroySelectedWorksheets}
              >
                <Button
                  background={Theme.red}
                  disabled={isBlank(this.selectedWorksheetIds)}
                  fontSize='14px'
                >
                  <i className="fa fa-trash"/>
                  {`${this.archivedFilter ? 'Unarchive' : 'Archive'} ${this.selectedWorksheetIds.length} post${this.selectedWorksheetIds.length > 1 ? 's' : ''}`}
                </Button>
              </DeleteButton>
            </ActionButtonsWrapper>
          </SelectedPostsWrapper>
        }
        <table className="table-wrapper">
          <thead>
            <tr>
              <th width="20" >
                <Checkbox
                  value={this.allWorksheetsSelected}
                  onClick={this.selectAllWorksheets}
                />
              </th>
              <th width="20" />
              <th
                width="350"
                className={cx('sortable', this.props.columnClassNames('name'))}
                onClick={() => this.props.onSortColumn('name')}
              >
                Name
              </th>
              <th
                  className={cx('sortable', this.props.columnClassNames('published_at'))}
                  onClick={() => this.props.onSortColumn('published_at', true)}
              >
                Published At
              </th>
              <th
                  className={cx('sortable', this.props.columnClassNames('content_published_at'))}
                  onClick={() => this.props.onSortColumn('content_published_at', true)}
              >
                Content Pub.
                <InfoTooltip>This is how posts are sorted in the Videos and Podcast pages.</InfoTooltip>
              </th>
              <th>Published</th>
              <th>Open To All</th>
              <th
                className={cx('sortable', this.props.columnClassNames('users_started_count'))}
                onClick={() => this.props.onSortColumn('users_started_count')}
              >
                # Started
              </th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              WorksheetStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Checkbox
                        value={this.selectedWorksheetIds.indexOf(item.id) !== -1}
                        onClick={() => this.onSelectWorksheet(item)}
                      />
                    </td>
                    <td>
                      <Link
                        to={{ name: 'creator.groups.worksheets.edit', params: { worksheetId: item.id, groupId: this.props.params.groupId } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <NameWrapper>
                        { item.archived && <InfoTooltip icon='archive'><div>Archived</div><div>Cannot be seen by users.</div></InfoTooltip> }
                        <InPlaceText
                          id={item.id}
                          defaultValue={item.name}
                          field="name"
                          model="worksheets"
                          color={item.archived ? 'gray' : null}
                        />
                        {
                          item.pinned &&
                            <PinIcon />
                        }
                      </NameWrapper>
                    </td>
                    <td>
                      {item.publishedAt?.formattedDateWithYearAndTime}
                    </td>
                    <td>
                      {item.contentPublishedAt?.formattedDateWithYear}
                    </td>
                    <td>
                      <div style={{display: 'flex'}}>
                        <CheckIfTrue
                          value={item.published}
                        />
                        {
                          !item.isValid &&
                            <InvalidIconWrapper
                              data-tip
                              data-for={`valid-publish-${item.id}`}
                            >
                              <i className='fa fa-warning' />
                              <ReactTooltip
                                type="dark"
                                effect="solid"
                                id={`valid-publish-${item.id}`}
                                class="custom-tooltip"
                              >
                                <div>The worksheet is invalid.</div>
                              </ReactTooltip>
                            </InvalidIconWrapper>
                        }
                        {
                          item.isValid && item.hasUnpublishedVocab &&
                            <InvalidIconWrapper
                              data-tip
                              data-for={`valid-publish-vocab-${item.id}`}
                            >
                              <i className='fa fa-warning' />
                              <ReactTooltip
                                type="dark"
                                effect="solid"
                                id={`valid-publish-vocab-${item.id}`}
                                class="custom-tooltip"
                              >
                                <div>This worksheet is valid but</div>
                                <div>has unpublished vocab</div>
                              </ReactTooltip>
                            </InvalidIconWrapper>
                        }
                      </div>
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.accessWithoutMembership}
                      />
                    </td>
                    <td>
                      {item.usersStartedCount}
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onToggleArchive(item.id)}
                        message={`${this.archivedFilter ? 'Unarchive' : 'Archive'} this worksheet?`}
                        className="fa-block right"
                        faIcon={this.archivedFilter ? 'fa-undo' : 'fa-times'}
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={WorksheetStore}
          ids={{groupId: this.props.params.groupId}}
          queryStrings={{show_all: true, expanded: true}}
          onPagination={() => this.selectedWorksheetIds = []}
        />
      </div>
    );
  }
}

export default SortableColumns(WorksheetIndex, WorksheetStore, { sortColumn: 'published_at', sortIsAscending: false });

