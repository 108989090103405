import React from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { getCookieValue, setCookieValue } from '@seedlang/utils';
import { noop } from 'lodash';

const Wrapper = styled.div`

`;

@observer
class RedirectToHome extends React.Component {

  constructor(props) {
    super(props);
    if (window.location.href.includes('/promotion/')) {
      const slug = window.location.href.split('/').includes('promotion') ? window.location.href.split('/')[window.location.href.split('/').indexOf('promotion') + 1] : null;
      const promotionCookie = `promotion_${slug}_tracked`;
      if (!getCookieValue(promotionCookie)) {
        setCookieValue(promotionCookie, true);
        setCookieValue('promotion-code', slug);
        AppUI.promotionStore.incrementVisitorsCount({ids: {slug: slug}}, noop);

      }
    }
    this.disposer = autorun(() => {
      if (AppUI.authUserStore.hasShowData) {
        AppUI.routeToHome();
      }
    })
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    return (
      <Wrapper />
    );
  }
}

export default ErrorBoundary(RedirectToHome);
