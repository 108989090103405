import React from 'react';
import { observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { RatingStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { isPresent, isBlank } from "@seedlang/utils/src";
import { AppUI } from "@seedlang/state";
import Text from 'components/text';

@observer
class RatingIndex extends React.Component {
  @observable sent = [];
  @observable filters;

  static defaultProps = {
    defaultFilters: {group_id: 'false'}
  }

  constructor (props) {
    super();
    if (props.simple) {
      this.filters = [
        {
          type: 'boolean',
          label: 'Comment',
          name: 'comment',
        },
        {
          type: 'number',
          name: 'rating',
          label: 'Rating',
        }
      ]
    } else {
      this.filters = [
        {
          type: 'select',
          name: 'language_id',
          options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
          labelField: 'name',
          label: 'Language',
          default: '',
        },
        {
          type: 'number',
          name: 'rating',
          label: 'Rating',
        },
        {
          type: 'boolean',
          label: 'Comment',
          name: 'comment',
        },
        {
          type: 'boolean',
          label: 'Promoted',
          name: 'promoted',
        },
        {
          type: 'boolean',
          label: 'Confirmed',
          name: 'confirmed',
        },
      ]
    }
  }

  sendEmail(rating) {
    RatingStore.sendEmail({ids: {ratingId: rating.id}}, this.afterSendEmail.bind(this, rating.id));
  }

  afterSendEmail(ratingId) {
    this.sent.push(ratingId);
  }

  render() {
    return (
      <div className='rating-index'>
      <Filter
        store={RatingStore}
        defaultSort="-created_at"
        namespace="rating-index"
        defaultFilters={this.props.defaultFilters}
        filters={this.filters}
        />
        <table className="table-wrapper">
          <thead>
            <tr>
              {
                !this.props.simple &&
                  <th width="20"/>
              }
              <th width="120">Created</th>
              <th width="80">User</th>
              {
                !this.props.simple &&
                  <th width="240">Deck</th>
              }
              <th width="30">Rating</th>
              <th>Comment</th>
              {
                this.props.showExercise &&
                <th width="150">Exercise</th>
              }
              {
                this.props.showExercise &&
                <th width="240">Worksheet</th>
              }
              {
                !this.props.simple &&
                  <th>Pro</th>
              }
              {
                !this.props.simple &&
                  <th>Cnf</th>
              }
              {
                !this.props.simple &&
                  <th></th>
              }
            </tr>
          </thead>
          <tbody>
            {
              RatingStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    {
                      !this.props.simple &&
                      <td>
                        <Link
                          to={{name: 'builder.ratings.edit', params: {ratingId: item.id}}}
                          className="on-click"
                        >
                          <i className="fa fa-chevron-right fa-block"/>
                        </Link>
                      </td>
                    }
                    <td>{item.createdAt.formatted}</td>
                    <td>
                      {
                        AppUI.permissions.canAccessBasicUserData && isPresent(item.user) &&
                          <Link
                            to={{name: isPresent(this.props.groupId) ? "creator.groups.users.edit" : "builder.users.edit", params: {userId: item.user?.id, groupId: this.props.groupId}}}
                          >
                            {isPresent(item.user.firstName) ? item.user.firstName : item.user?.name}
                          </Link>
                      }
                      {
                        AppUI.permissions.canAccessBasicUserData && isBlank(item.user) &&
                        <div>[deleted account]</div>
                      }
                      {!AppUI.permissions.canAccessBasicUserData && item.user?.name}
                    </td>
                    {
                      !this.props.simple &&
                      <td>
                        {
                          item.deck &&
                          <Link
                            to={{name: 'builder.decks.edit', params: {deckId: item.deck?.id}}}
                          >
                            {item.deck.name}
                          </Link>
                        }
                      </td>
                    }
                    <td>{item.rating}</td>
                    <td>{item.comment}</td>
                    {
                      this.props.showExercise &&
                      <td>
                        {
                          item.exercise && this.props.groupId &&
                          <Link
                            to={{name: 'creator.groups.exercises.edit', params: {groupId: this.props.groupId, exerciseId: item.exercise?.id, worksheetId: item.exercise?.worksheet?.id}}}
                          >
                          <div>{item.exercise.name}</div>
                          </Link>
                        }
                      </td>
                    }
                    {
                      this.props.showExercise &&
                      <td>
                        {
                          item.exercise && item.exercise.worksheet &&
                          <div>{item.exercise.worksheet?.name}</div>
                        }
                      </td>
                    }
                    {
                      !this.props.simple &&
                      <td>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.promoted}
                          field="promoted"
                          model="rating"
                          icon="check"
                        />
                      </td>
                    }
                    {
                      !this.props.simple &&
                      <td>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.confirmed}
                          field="confirmed"
                          model="rating"
                          icon="check"
                        />
                      </td>
                    }
                    {
                      !this.props.simple &&
                      <td>
                        {
                          item.comment && this.sent.indexOf(item.id) === -1 &&
                          <i
                            className='fa fa-envelope fa-block send-test-mail fa-on-click'
                            onClick={() => this.sendEmail(item)}
                          />
                        }
                      </td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={RatingStore}
        />
      </div>
    );
  }
}

export default RatingIndex;
