import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import { isPresent, isBlank } from "@seedlang/utils";
import autobind from "autobind-decorator";
import { AppUI, ExerciseUI } from "@seedlang/state";

const TagDisplay = styled.div`
  background: #FFF;
  color: #343535;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 5px 0 0;
  border-radius: 15px;
  font-weight: bold;
  border: 1px solid #cbcccb;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  &:hover {
    box-shadow: ${props => props.isClickable ? '0 0 6px rgba(33,33,33,.2)' : ''}; 
  }
  &:active {
    box-shadow: ${props => props.isClickable ? 'inset 0 0 6px rgba(33,33,33,.2)' : ''};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: ${props => props.width};
  justify-content: space-between;
`;

const TagDisplayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EpisodeCode = styled.div`
  font-size: 14px;
  color: #616161;
  margin-top: 5px;
  text-wrap: nowrap;
`;

@observer
class Tags extends React.Component {

  static defaultProps = {
    width: '100%',
  }

  @autobind onClickWorksheetTag(worksheetTag) {
    if (!AppUI.site.showWorksheetFilter || isBlank(AppUI.user.currentUserGroup)) { return }
    if (isPresent(worksheetTag.tag)) {
      AppUI.user.currentUserGroup.set('worksheetTagIds', worksheetTag?.tag?.id);
      AppUI.userGroupStore.update({
        ids: {userGroupId: AppUI.user.currentUserGroup?.id},
        data: {worksheetTagIds: worksheetTag?.tag?.id}
      }, this.afterClickTag);
    }
  }

  @autobind afterClickTag() {
    AppUI.loadUser();
    this.props.onUpdateFilter();
  }

  render() {
    return (
      <Wrapper width={this.props.width}>
        <TagDisplayWrapper>
            {
              isPresent(this.props.worksheet?.worksheetTags) && sortBy(this.props.worksheet.worksheetTags, (item) =>  item.tag?.tagCategoryPosition).map((item) => {
                if (item.tag?.name && item.tag?.tagCategoryPublished) {
                  return (
                    <TagDisplay
                      key={item.id}
                      onClick={() => this.onClickWorksheetTag(item)}
                      isClickable={AppUI.site.showWorksheetFilter && isPresent(AppUI.user.currentUserGroup)}
                    >
                      {item.tag.name}
                    </TagDisplay>
                  )
                } else {
                  return null;
                }
              })
            }
        </TagDisplayWrapper>
         {
            this.props.withEpisodeCode && isPresent(this.props.worksheet.episodeCode) &&
            <EpisodeCode>{this.props.worksheet.episodeCode}</EpisodeCode>
          }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Tags);
