import React from 'react';
import { observer } from 'mobx-react';
import { ExerciseCardWrapper } from '@seedlang/hoc';
import ExerciseInputOptions from 'components/exercise/exercise_input_options';
import ExerciseInputDrag from 'components/exercise/exercise_input_drag';
import ExerciseInputEnter from 'components/exercise/exercise_input_enter';
import ExerciseMatchText from 'components/exercise/exercise_match_text';
import ExerciseReorder from 'components/exercise/exercise_reorder';
import ExerciseMatchMedia from 'components/exercise/exercise_match_media';
import ExerciseHighlight from 'components/exercise/exercise_highlight';
import ExerciseSentenceEnter from 'components/exercise/exercise_sentence_enter';
import { isPresent } from '@seedlang/utils';
import { DeckUI } from '@seedlang/state';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import { Theme } from '@seedlang/constants';
import { RightIcon } from '@seedlang/icons';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import EvaluateButtons from 'components/card/evaluate_buttons';
import ExerciseMultipleChoiceCard from "components/exercise/exercise_multiple_choice_card";

const COMPONENTS = {
  matchText: ExerciseMatchText,
  reorder: ExerciseReorder,
  inputEnter: ExerciseInputEnter,
  inputDrag: ExerciseInputDrag,
  inputOptions: ExerciseInputOptions,
  matchMedia: ExerciseMatchMedia,
  multipleChoice: ExerciseMultipleChoiceCard,
  sentenceEnter: ExerciseSentenceEnter,
  highlight: ExerciseHighlight,
};

@observer
class ExerciseCard extends React.Component {

  render() {
    return (
      <CardWrapper
        icons={this.props.icons}
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
      >
        <CardContent
          scroll
        >
          {
            isPresent(this.props.exercise) && isPresent(this.props.exercise.exerciseType) && isPresent(this.props.exercise.exerciseType.slug) &&
              React.createElement(COMPONENTS[this.props.exercise.exerciseType.slug], {
                inDeck: true,
                exercise: this.props.exercise,
                card: this.props.card,
                userCard: this.props.userCard,
                cardState: this.props.cardState,
                inView: this.props.inView,
              })
          }
        </CardContent>
        {
          !this.props.cardState.revealed && this.props.exercise.exerciseType?.slug !== 'multipleChoice' &&
            <CardButtons>
              <CardButton
                submitOnEnter
                shortcut="1"
                text="Show Answer"
                backgroundColor={Theme.darkOrange}
                icon={<RightIcon />}
                disabled={this.props.showAnswerDisabled}
                onClick={() => DeckUI.showExerciseAnswer(this.props.card, this.props.cardState, this.props.userCard)}
              />
            </CardButtons>
        }
        {
          (this.props.cardState.revealed || this.props.exercise.exerciseType?.slug === 'multipleChoice') &&
            <EvaluateButtons
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView || (this.props.exercise.exerciseType?.slug === 'multipleChoice' && !this.props.cardState.revealed)}
            />
        }
      </CardWrapper>
    );
  }
}

export default ExerciseCardWrapper(ExerciseCard);
