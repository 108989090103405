import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { ServerStore } from '@seedlang/stores';
import { isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import FormWrapper from 'components/authenticate/form_wrapper';
import Button from 'components/button/button';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import RegistrationForm from './registration_form';

const AuthLinkWrapper = styled.div`
  margin: ${props => props.margin};
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const TextLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin: ${props => props.margin};
  a {
    color: ${props => props.color};
  }
  color: ${props => props.color};
`;

@observer
class AuthenticateRegister extends React.Component {
  @observable expanded = false;

  get isExpanded() {
    return !this.props.expandEmail || AppUI.expandedEmailRegistrationFields;
  }

  @computed get showButton() {

  }

  @computed get isButtonOnly() {
    return !AppUI.siteIsDefault && !this.props.signInCard && !this.props.signInModal && !AppUI.expandedEmailRegistrationFields;
  }

  componentDidMount() {
    ServerStore.clearMessages();
  }

  @autobind expandFormFields() {
    AppUI.set('expandedEmailRegistrationFields', true);
  }

  @computed get showForm() {
    return true;
  }

  render() {
    return (
      <FormWrapper
        padding={0}
        background={this.props.background}
        width={this.props.width}
        borderRadius={this.props.borderRadius}
      >
        {
          !this.showForm &&
            <Button
              onClick={this.expandFormFields}
              scaleOnHover="1.05"
              height="50px"
              borderRadius="50px"
              width="100%"
              background={AppUI.siteIsDefault ? Theme.blue : AppUI.site.accentColor }
              color="#FFF"
              backgroundOnHover="#FFF"
            >
              Register With Email
            </Button>
        }
        {
          this.showForm &&
            <RegistrationForm
              checkout={this.props.checkout}
              showEmailConfirmation={this.props.showEmailConfirmation}
              showTerms={this.props.showTerms}
              hidePrivacy={this.props.hidePrivacy}
              hideTitle={this.props.hideTitle}
              hideSpinner={this.props.hideSpinner}
              color={this.props.color}
              levelId={this.props.levelId}
              languageId={this.props.languageId}
              targetLanguageId={this.props.targetLanguageId}
              onCloseModal={this.props.onCloseModal}
              afterRegistration={this.props.afterRegistration}
              onConfirmationRequired={this.props.onConfirmationRequired}
            />
        }

        {
          this.props.contentAfterButton
        }

        {
          this.props.onSigninLinkClick && (
            <AuthLinkWrapper
              margin={AppUI.siteIsDefault ? "20px 0 0 0" : "0px"}
            >
              <TextLink
                onClick={this.props.onSigninLinkClick}
                color={AppUI.siteIsDefault || isBlank(AppUI.site?.footerBackgroundColor) ? '#FFF' : AppUI.site?.footerBackgroundColor}
              >
                Already have an account?
              </TextLink>
            </AuthLinkWrapper>
          )
        }
      </FormWrapper>
    );
  }
}

export default AuthenticateRegister;
