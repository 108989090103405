import styled from '@emotion/styled';
import { Theme } from "@seedlang/constants/src";
import { AppUI, AfterAuth } from '@seedlang/state';
import { AuthenticateStore, ServerStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import FormWrapper from 'components/authenticate/form_wrapper';
import Button from 'components/button/button';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import { isBlank } from '@seedlang/utils';

const Alert = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #333;
  margin: 20px 0;
  text-align: center;
  color: ${(props) => props.color};
  text-wrap: balance;
`;

const LinkWrapper = styled.div`
  margin: ${props => props.margin};
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const TextLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin: ${props => props.margin};
  a {
    color: ${props => props.color};
  }
  color: ${props => props.color};
`;

@observer
class AuthenticateConfirm extends React.Component {
  @observable loading = false;
  @observable emailResent = false;
  @observable errorMessage = null;
  @observable mode = 'confirmationRequired';

  componentDidMount() {
    ServerStore.clearMessages();

    if (this.tokenFromUrl) {
      this.validateToken();
    }
  }

  get tokenFromUrl() {
    return new URLSearchParams(window.location.search).get('token');
  }

  @autobind async onResendClick() {
    try {
      this.errorMessage = null;
      this.loading = true;
      this.mode = 'resendingEmail';

      await AuthenticateStore.sendConfirmationInstructions();
      this.mode = 'emailResent';
    }
    catch (error) {
      console.error(error);
      this.errorMessage = error.message ?? 'Sorry, there was an error sending the confirmation email.'
    }
    finally {
      this.loading = false;
    }
  }

  @autobind async validateToken() {
    try {
      this.errorMessage = null;
      this.loading = true;
      this.mode = 'validatingToken'

      await AuthenticateStore.validateEmailConfirmationToken({data: {token: this.tokenFromUrl}});
      await AppUI.loadUserPromise();

      this.mode = 'confirmed';
    }
    catch (error) {
      console.error(error);
      this.errorMessage = error.message ?? 'Sorry, there was an error validating your email address.'
    }
    finally {
      this.loading = false;
    }
  }

  @autobind onContinueClick() {
    AfterAuth.clear();
  }

  get nextStep() {
    if (AfterAuth.value) return AfterAuth.value;

    if (AppUI.user?.enterNewPassword) {
      return {
        path: '/onboarding/create_password'
      }
    }
  }

  render() {
    return (
      <FormWrapper
        padding={0}
        background={this.props.background}
        width={this.props.width}
        borderRadius={this.props.borderRadius}
      >
        {
          this.loading ? (
            <>
              <Title>Email Confirmation</Title>
              <Spinner background={AppUI.site.accentColor || Theme.blue} />
            </>
          ) :
          this.errorMessage ? (
            <>
              <Title>Email Confirmation</Title>
              <Alert color={AppUI.siteIsDefault ? "#FFF" : "#333"}>
                {/* E.g.: Email was already confirmed, please try signing in */}
                {this.errorMessage}
              </Alert>
            </>
          ) :
          this.mode === 'confirmationRequired' ? (
            <>
              <Title>Please confirm your email address</Title>
              <Alert color={AppUI.siteIsDefault ? "#FFF" : "#333"}>
                <p>
                  Please click the link in the email we have sent you.
                </p>
                <p>
                  Please allow a few minutes for the email to arrive and check your Spam folder.
                </p>
                <Button
                  height="50px"
                  width="100%"
                  borderRadius="50px"
                  background={AppUI.site.accentColor}
                  backgroundOnHover={AppUI.site.accentColor}
                  color="#FFF"
                  onClick={this.onResendClick}
                >
                  Resend email
                </Button>
              </Alert>
            </>
          ) :
          this.mode === 'emailResent' ? (
            <>
              <Title>Please confirm your email address</Title>
              <Alert background={Theme.green} padding={10} marginTop={10}>
                <p>
                  The confirmation email has been resent.
                </p>
                <p>
                  Please allow a few minutes for the email to arrive and check your Spam folder.
                </p>
              </Alert>
            </>
          ) :
          this.mode === 'confirmed' ? (
            <>
              <Title>Email confirmed</Title>
              <Alert background={Theme.green} padding={10} marginTop={10}>
                <p>
                  Your email address is now confirmed.
                </p>
                {
                  this.nextStep?.path &&
                    <Link to={this.nextStep.path}>
                      <Button
                        height="50px"
                        width="100%"
                        borderRadius="50px"
                        background={AppUI.site.accentColor}
                        backgroundOnHover={AppUI.site.accentColor}
                        color="#FFF"
                        onClick={this.onContinueClick}
                      >
                        {this.nextStep.label ?? `Continue`}
                      </Button>
                    </Link>
                }
              </Alert>
            </>
          ) : (
            <>
              <Title>Email Confirmation</Title>
              <Alert color={AppUI.siteIsDefault ? "#FFF" : "#333"}>
                Something went wrong. Please try again.
              </Alert>
            </>
          )
        }

        {
          !this.loading && (
            <LinkWrapper>
              <Link to={{
                name: AppUI.siteIsDefault ? 'landing' : 'worksheets.index' }}>
                <TextLink
                  color={AppUI.siteIsDefault || isBlank(AppUI.site?.footerBackgroundColor) ? '#FFF' : AppUI.site?.footerBackgroundColor}
                >
                  Go Home
                </TextLink>
              </Link>
            </LinkWrapper>
          )
        }
      </FormWrapper>
    );
  }
}

const Title = ({ children }) => (
  <Text
    center
    heading="3"
    color={AppUI.siteIsDefault ? "#FFF" : "#333"}
  >
    {children}
  </Text>
)

export default AuthenticateConfirm;
