import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { OnboardingRegistrationWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import AuthenticateSignin from 'components/authenticate/authenticate_signin';
import AuthenticateForgotPassword from 'components/authenticate/authenticate_forgot_password';
import AuthenticateConfirm from 'components/authenticate/authenticate_confirm';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { CloseIcon } from '@seedlang/icons';
import Spinner from 'components/spinner';
import { isBlank, isPresent, sleep } from '@seedlang/utils';
import Alert from 'components/alert';
import WhitelabelLogo from "components/whitelabel_logo";
import { Link } from "react-router";
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  position: relative;
  .patreon-logo {
    border-radius: 4px;
    width: 17px;
    margin-right: 5px;
    margin-top: 3px;
  }
  .fa-warning {
    color: #333!important;
  }
  .facebook-button {
    margin: 0 0 10px 0!important;
  }
  button {
    width: 100%;
  }
  label {
    font-size: 14px;
    margin-bottom: 0;
  }
  .form-wrapper {
    padding: 0;
  }
`;

const RegisterButton = styled.div`
  position: relative;
  color: #FFF;
  margin: -10px 0 10px 0;
  display: flex;
  align-items: center;
  background: #333;
  padding: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  img {
    width: 18px;
    margin-right: 5px;
  }
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const TextLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin: ${props => props.margin};
  a {
    color: ${props => props.color};
  }
  color: ${props => props.color};
`;

const AuthLinkWrapper = styled.div`
  margin: ${props => props.margin};
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: -10px;
  .close-icon {
    width: 30px;
  }
  svg {
    width: 30px;
    fill: #333;
  }
  margin-top: -50px;
`;

const WhitePanel = styled.div`
  padding: 20px;
  background: whitesmoke;
  border-radius: 10px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  .img {
    width: 190px;
  }
`;

const PatreonLink = styled.div`
  font-size: 14px;
`;


@observer
class OnboardingWhitelabelRegistration extends React.Component {
  @observable submittedRegistration = false;
  @observable authType = this.props.authType;

  static defaultProps = {
    authType: 'registration',
  }

  constructor(props) {
    super(props);
    AppUI.createEvent('onboarding - view sign up', {device: 'web'});
    this.initState();
  }

  async initState() {
    await sleep(1); // wait for routeName to update after navigation
    const routeName = AppUI.routeStore.routeName;

    switch (routeName) {
      case 'onboarding.confirm':
        this.authType = 'confirmEmail';
        break;
      case 'onboarding.sign_in':
        this.authType = 'signIn';
        break;
      case 'onboarding.forgot_password':
        this.authType = 'forgotPassword';
        break;
    }
  }

  componentDidMount() {
    if (AppUI.user?.id && !AppUI.user.guest && this.authType === 'signIn') {
      AppUI.routeToHome();
    }
  }

  @computed get groupId() {
    return window.location.href.split("/group/")[1];
  }

  @autobind afterRegistration() {
    this.submittedRegistration = true;
    this.props.afterRegistration();
  }

  @autobind reset() {
    this.submittedRegistration = false;
    this.authType = 'registration';
    AppUI.set('expandedEmailRegistrationFields', false);
  }

  @autobind signIn() {
    this.authType = 'signIn';
  }

  @autobind onClickForgotPassword() {
    this.authType = 'forgotPassword';
  }

  @autobind gotoMemberships() {
    AppUI.routeStore.routeToNamed('memberships.index');
  }

  @autobind onConfirmationRequired(unconfirmedDetails) {
    this.authType = 'confirmEmail';
  }

  render() {
    return (
      <Wrapper
        justifyContent={'flex-start'}
      >
        {
          AppUI.expandedEmailRegistrationFields &&
            <CloseIconWrapper>
              <CloseIcon
                onClick={() => this.reset()}
              />
            </CloseIconWrapper>
        }
        {
          isPresent(AppUI.site.image) &&
            <LogoWrapper>
              <WhitelabelLogo
                image={AppUI.site.image}
              />
            </LogoWrapper>
        }
        <Content>
          {
            this.props.showSpinner &&
              <Spinner
                background={Theme.blue}
              />
          }
          {
            !this.props.showSpinner && this.authType === 'forgotPassword' &&
              <AuthenticateForgotPassword
                hideSpinner
                onboarding
                padding="0px 0px 20px 0px"
                show={this.props.show}
              />
          }
          {
            !this.props.showSpinner && this.authType === 'signIn' &&
              <AuthenticateSignin
                hideSpinner
                onboarding
                padding="0px 0px 20px 0px"
                show={this.props.show}
                onConfirmationRequired={this.onConfirmationRequired}
              />
          }
          {
            !this.props.showSpinner && this.authType === 'confirmEmail' &&
              <AuthenticateConfirm />
          }
          {
            this.props.targetLanguageId && !this.groupId && !this.props.showSpinner && !this.props.hideGuestSignIn && this.authType === 'registration' && !this.submittedRegistration && !AppUI.registrationSubmitted && !AppUI.expandedEmailRegistrationFields && !AppUI.userIsLoaded &&
              <Button
                height="50px"
                borderRadius="50px"
                background="#8D8D8D"
                margin="10px 0 0 0"
                scaleOnHover={1.05}
                backgroundOnHover="#9d9d9d"
                onClick={this.props.onSkipRegistration}
              >
                Skip For Now
              </Button>
          }
          {
            !this.props.showSpinner &&
              <AuthLinkWrapper margin="0px">
                {
                  ['signIn'].indexOf(this.authType) !== -1 &&
                    <RegisterButton
                      onClick={this.gotoMemberships}
                    >
                      Become a Member
                    </RegisterButton>
                }
                {
                  this.authType === 'signIn' && AppUI.site.allowPatreonSignIn &&
                    <PatreonLink>
                      Already a Member on Patreon? <Link className='underline' to={{name: 'onboarding.patreon'}} >Connect here.</Link>
                    </PatreonLink>
                }
                {
                  ['signIn'].indexOf(this.authType) !== -1 &&
                    <TextLink
                      onClick={this.onClickForgotPassword}
                      color={isBlank(AppUI.site?.footerBackgroundColor) ? '#333' : AppUI.site?.footerBackgroundColor}
                    >
                      Forgot Password?
                    </TextLink>
                }
                {
                  ['forgotPassword'].indexOf(this.authType) !== -1 &&
                    <TextLink
                      onClick={this.signIn}
                      color={isBlank(AppUI.site?.footerBackgroundColor) ? '#333' : AppUI.site?.footerBackgroundColor}
                    >
                      Sign In to {AppUI.user.site.name}
                    </TextLink>
                }
                {
                this.authType === 'forgotPassword' &&
                    <Alert
                      padding="10px 10px 10px 10px"
                      textAlign="center"
                      margin="10px 0 0 0"
                      background="#7e7e7e"
                      borderRadius="5px"
                      border="1px solid #333"
                    >
                      <i className='fa fa-warning' /> Note that your credentials between Seedlang and all Easy Languages sites are linked.
                    </Alert>
                }
              </AuthLinkWrapper>
          }
        </Content>
      </Wrapper>
    );
  }
}

export default OnboardingRegistrationWrapper(OnboardingWhitelabelRegistration);
