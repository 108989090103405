import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Navigation from 'components/navigation';
import { last } from 'lodash';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import { AppUI, ForumUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { admin } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${admin()}
  margin-top: 20px;
  ul {
    margin-bottom: 20px;
  }
  li {
    margin-left: 20px!important;
    list-style-type: disc!important;
  }
`;

const NavigationRow = styled.div`
  display: flex;
  align-items: center;
`;

const NavigationWrapper = styled.div`
  flex: 1;
`;

const Language = styled.div`
  width: 100px;
`;

const Message = styled.div`
  background: #FFF;
  padding: 20px;
`;

@observer
class Translator extends React.Component {

  constructor(props) {
    super(props);
    AppUI.languageStore.getShow({ids: {languageId: props.params.languageId}})
  }

  @autobind updateLanguage(val) {
    AppUI.routeStore.routeToNamed(AppUI.routeStore.routeName, {languageId: val});
    AppUI.authUserStore.update({data: {language_id: val}}, this.afterUpdateUser)
  }

  @autobind afterUpdateUser() {
    AppUI.loadUser(this.afterLoadUser);
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
    if (AppUI.routeStore.routeName === 'translator.forum_threads.show') {
      ForumUI.loadForum(AppUI.user.languageForumId, true);
      ForumUI.forumThreadStore.getIndex({filters: {hidden: false, forum_id: AppUI.user.languageForumId}});
    }
  }

  render () {
    return (
      <Wrapper>
        <div className="builder">
          <h3>An Important Update</h3>
          <Message>
            <p>Thank you to everyone that has helped us with our translator program, over the years our translators have created more than 100k translations for our community and helped to make the app more accessible to many people all around the world.</p>
            <p>Recent advances in AI have made it so that we feel confident that we can produce high quality translations automatically across as many as 30 languages. But we would still very much appreciate your help! Over the next weeks we will transition the translator program to where our translators can help with:</p>
            <ul>
              <li>Reviewing translations that were flagged by our students.</li>
              <li>Helping us with translating labels that appear within the app.</li>
              <li>Reviewing text for our grammar descriptions.</li>
            </ul>
            <p>Our ultimate goal is that when someone uses the app, that they never have to see any English at all. We appreciate your help in making this goal a reality!</p>
            <p>If you have any questions or concerns, please email us at help@seedlang.com.</p>
            <p>Thanks again for all of your help!</p>
            <p>🎉🎉🎉🎉</p>
          </Message>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Translator);
