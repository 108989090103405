import React from 'react';
import styled from '@emotion/styled';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import NavigationItemCreate from 'pages/builder/navigation_items/navigation_item_create';
import { observer } from 'mobx-react';

const Wrapper = styled.div`
 
`;

@observer
class NavigationItemIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getNavigationItems();
  }

  @autobind getNavigationItems() {
    AppUI.navigationItemStore.getIndex();
  }

  onNavItemDelete(id) {
    AppUI.navigationItemStore.destroy({ ids: { navigationItemId: id }}, this.getNavigationItems);
  }

  render() {
    return (
      <Wrapper>
        <NavigationItemCreate
          siteId={AppUI.site.id}
          afterCreate={this.getNavigationItems}
        />
        <table className="table-wrapper admin-table">
          <thead>
          <tr>
            <th>Position</th>
            <th>Name</th>
            <th>Route Name</th>
            <th>Path</th>
            <th>Route Ids</th>
            <th>Signed In</th>
            <th>Signed Out</th>
            <th>Footer</th>
            <th>Settings</th>
            <th width="40" />
          </tr>
          </thead>
          <tbody>
          {
            AppUI.navigationItemStore.indexData.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.position}
                      model="navigation_items"
                      field="position"
                      afterChange={this.getNavigationItems}
                    />
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.name}
                      model="navigation_items"
                      field="name"
                    />
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.routeName}
                      model="navigation_items"
                      field="route_name"
                    />
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.path}
                      model="navigation_items"
                      field="path"
                    />
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.routeIds}
                      model="navigation_items"
                      field="route_ids"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.showWhenSignedIn}
                      model="navigation_items"
                      field="show_when_signed_in"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.showWhenSignedIn}
                      model="navigation_items"
                      field="show_when_signed_out"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.footerLink}
                      model="navigation_items"
                      field="footer_link"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.settingsLink}
                      model="navigation_items"
                      field="settings_link"
                      icon="check"
                    />
                  </td>
                  <td>
                    <DeleteButton
                      onConfirm={() => this.onNavItemDelete(item.id)}
                      message={"Delete this navigation item?"}
                      className="fa-block right"
                    />
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default NavigationItemIndex;
