import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  height: 210px;
  overflow: scroll;
  margin: 10px 0;
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 13px;
    line-height: 14px;
  }
`;

@observer
class DailyActivityInfo extends React.Component {

  render () {
    return (
      <Wrapper>
        <h3>What is XP?</h3>
        <p>XP stands for "experience points", and is given for performing learning activities across Seedlang.</p>
        <h3>What is a Streak?</h3>
        <p>A "streak" is the number of consecutive days in which you meet your XP requirements.</p>
        <h3>What if I lose my streak?</h3>
        <p>You can repair your streak by reaching {AppUI.user.streakExtendGoal}XP in one day. However, you can only repair the last 90 days.</p>
        <p>If you are a Pro member, you can also repair your streak once a month by clicking this button.</p>
        <Link
          to={{name: 'settings.streak_repair'}}
        >
          <Button
            padding="10px"
            borderRadius="5px"
            fontSize="13px"
          >
            Repair Streak
          </Button>
        </Link>
        <h3>Can I update my time zone?</h3>
        <p>Yes, you can do this by going to your settings page.</p>
        <Link
          to={{name: 'settings.user_profile'}}
        >
          <Button
            padding="10px"
            borderRadius="5px"
            fontSize="13px"
          >
            Settings Page
          </Button>
        </Link>
      </Wrapper>
    );
  }
}

export default DailyActivityInfo;
